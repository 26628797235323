<template>
    <div class="pagination v-select-tablas">
        <span style="padding-right:10px;">Mostrar:</span>
        
        <v-select
            class="show-rows"
            :items="perPage"
            color="#828282"
            item-color="#828282"
            v-model="tableData.items_to_show"
            dense
            outlined
            append-icon="keyboard_arrow_down"
        >
        </v-select>

        <a href="javascript:void(0)" class="page"> {{ from }} - {{ to }} de {{ total }} </a>

        <ul :class="paginationClasses.ul">
            <li v-if="paginationLabels.first" @click="first" :class="`${paginationClasses.li} pagination-box ${hasFirst ? paginationClasses.liDisable : ''}`">
                <i class="fa fa-chevron-left icon" aria-hidden="true"></i>
            </li>

            <li
                v-for="page in items"
                :key="page.label"
                @click="goto(page)"
                :class="`${paginationClasses.li} pagination-box ${page.active ? paginationClasses.liActive : ''} ${page.disable ? paginationClasses.liDisable : ''}`"
            >
                <span
                    v-if="page.disable"
                    :class="`${paginationClasses.button} ${paginationClasses.buttonDisable}`"
                >
                    ...
                </span>
                <button
                    v-else
                    :class="`${paginationClasses.button} ${page.active ? paginationClasses.buttonActive : ''}`"
                >
                    {{ page.label }}
                </button>
            </li>

            <li v-if="paginationLabels.last" @click="last" :class="`${paginationClasses.li} pagination-box ${hasLast ? paginationClasses.liDisable : ''}`">
                <i class="fa fa-chevron-right icon" aria-hidden="true"></i>
            </li>
        </ul>
    </div>
</template>

<script>
const defaultClasses = {
    ul: '',
    li: 'page',
    liActive: 'page-active',
    liDisable: 'disabled',
    button: 'pagination-link',
    buttonActive: 'pagination-link--active',
    buttonDisable: 'disabled'
}

const defaultLabels = {
    first: '&laquo;',
    prev: '&lsaquo;',
    next: '&rsaquo;',
    last: '&raquo;'
}

export default {
    props: {
        from: {
            type: Number,
            default: 0,
            required: true
        },
        to: {
            type: Number,
            default: 0,
            required: true
        },
        total: {
            type: Number,
            default: 0,
            required: true
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        lastPage: {
            type: Number,
            default: 1,
        },
        tableData: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        perPage: {
            type: Array,
            default: () => ([]),
            required: true,
        },
        classes: {
            type: Object,
            required: false,
            default: () => ({})
        },
        labels: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            pagination: {
                from: this.min,
                to: this.max,
                totalRecord: this.total
            },
            paginationClasses: {
                ...defaultClasses,
                ...this.classes
            },
            paginationLabels: {
                ...defaultLabels,
                ...this.labels
            }
        }
    },
    computed: {
        items() {
            let valPrev     = this.currentPage > 1 ? (this.currentPage - 1) : 1 // for easier navigation - gives one previous page
            let valNext     = this.currentPage < this.lastPage ? (this.currentPage + 1) : this.lastPage // one next page
            let extraPrev   = valPrev === 3 ? 2 : null
            let extraNext   = valNext === (this.lastPage - 2) ? (this.lastPage - 1) : null
            let dotsBefore  = valPrev > 3 ? 2 : null
            let dotsAfter   = valNext < (this.lastPage - 2) ? (this.lastPage - 1) : null

            let output = []

            for (let i = 1; i <= this.lastPage; i += 1) {
            if ([1, this.lastPage, this.currentPage, valPrev, valNext, extraPrev, extraNext, dotsBefore, dotsAfter].includes(i)) {
                output.push({
                    label: i,
                    active: this.currentPage === i,
                    disable: [dotsBefore, dotsAfter].includes(i)
                })
            }
            }

            return output
        },

        hasFirst() {
            return (this.currentPage === 1)
        },

        hasLast() {
            return (this.currentPage === this.lastPage)
        },
    },
    watch: {
        'tableData.items_to_show': function() {
            setTimeout(() => {
                $(".v-select__selection.v-select__selection--comma").css({'color' : '#828282'});
            },500)
        }
    },
    methods: {
        first() {
            if (!this.hasFirst) {
                this.$emit('getPaginationAction', {type: 'page', value: 1});
            }
        },

        goto(page) {
            if(!page.disable) {
                this.$emit('getPaginationAction', {type: 'page', value: page.label});
            }
        },

        last() {
            if (!this.hasLast) {
                this.$emit('getPaginationAction', {type: 'page', value: this.lastPage});
            }
        }
    },
    mounted() {
        $(".v-select__selection.v-select__selection--comma").css({'color' : '#828282'});
    }
}
</script>

<style scoped>
    .pagination {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color:#828282;
        padding: 20px;
        margin-bottom: 20px;
        display: inline-block;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
    }

    .page {
        display: inline;
        padding: 0px 9px;
        text-decoration: none;
        /* color: #BAC1D8; */
        color:#828282;
        font-family: Montserrat;
        font-size: 14px !important;
        font-weight: 500;
        line-height: 12px;
        text-align: left;
    }

    ul {
        display: inline-block;
        padding: 0;
        margin: 0;
    }

    ul li {
        display: inline;
    }

    li.pagination-box {
        padding: 10px 16px;
        transition: background-color .3s;
        border: 1px solid #E0E0E0;
        box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
        cursor: pointer;
    }

    li.pagination-box:first-child {
        border-radius: 8px 0 0 8px;
    }

    li.pagination-box:last-child {
        border-radius: 0 8px 8px 0;
    }

    li.pagination-box.page-active {
        background-color: #bdcceb;

    }

    .pagination-box > .icon {
        color: #828282;
        font-weight: 600 !important;
        font-size: 12px !important;
    }

    .show-rows {
        /*
        display: inline-block;
        padding: 0px 9px;
        margin-right: 4px;
        width: 150px !important;*/
        display: inline-block;
        width: 85px !important;
        height: 33px !important;
        margin-right: 4px;
        color: #828282;
        background-color: #fff;
    }

    .page.disabled {
        opacity: 0.65; 
        cursor: not-allowed;
    }
    @media only screen and (max-width: 712px) {
 ul {
        margin-top:40px;
    }
   
}
</style>