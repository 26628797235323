<template>
    <div class="row">
       
        <div class="margen" style="display: flex; width:100%;">
            <div style="display: flex; width:100%; justify-content: flex-start;">
                <hr class="vr"/>
                <p class="tblName pb-2">{{tableName}}</p>
            </div>
            <div style="display: flex; width:100%; justify-content: flex-end;">
                <slot name="actionButtonsLeft" v-if="actionButtonsLeftSlot"></slot>
                <div
                    class="icon-container d-flex align-center justify-center"
                    @click="openModal()"
                    v-if="showAdvancedFilters"
                >
                    <v-icon>mdi-magnify</v-icon>
                </div>
                <slot name="actionButtons" v-if="actionButtonsSlot"></slot>
            </div>
        </div>
        <div style="width:100%; overflow-x:auto" id="tableScrollX">
            <vue-table
                @sort="sortBy"
                :sortKey="sortKey"
                :Order="Order"
                :columns="columns"
                :sortOrders="sortOrders"
                :table-classes="classes.table"
                :table-header-classes="classes['t-head']"
                :table-row-classes="classes['t-head-tr']"
                :table-container-classes="classes['table-container']">
                <template v-if="bodySlot">
                    <template v-if="loading">
                        <tbody>
                            <tr>
                                <td :colspan="columns.length" >
                                    <v-progress-linear class="loading" :active="loading" color="blue" indeterminate></v-progress-linear>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                    <template v-else-if="items.length == 0">
                    <tbody>
                            <tr>
                                <td :class="emptyTable" :colspan="columns.length">
                                    No hay elementos para mostrar
                                </td>
                            </tr>
                        </tbody>
                    </template>

                    <template v-else>
                        <slot
                            name="body"
                            v-if="bodySlot"
                            :data="items">
                        </slot>
                    </template>
                </template>

                <template v-else>
                    <tbody
                        v-if="!! columns"
                        :class="classes['t-body']">
                        <tr
                            :key="item.id"
                            v-for="item in data.data"
                            :class="classes['t-body-tr']">
                            <td
                                :key="column.name"
                                :class="classes.td"
                                v-for="column in columns">
                                <data-table-cell
                                    :value="item"
                                    :name="column.name"
                                    :meta="column.meta"
                                    :event="column.event"
                                    :comp="column.component"
                                    :classes="column.classes"
                                    :handler="column.handler">
                                </data-table-cell>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </vue-table>
        </div>
        <div class="display-row pagination-container">
            <div>
            <slot
                :meta="data.meta"
                name="pagination"
                :links="data.links"
                v-if="paginationSlot">
            </slot>
            <data-table-pagination 
                v-else
                @getPaginationAction="getPageAction"
                :from="paginationInfo.firstItem != null ? paginationInfo.firstItem  : 0"
                :to="paginationInfo.lastItem != null    ? paginationInfo.lastItem   : 0"
                :total="paginationInfo.total"
                :currentPage="paginationInfo.currentPage"
                :lastPage="paginationInfo.lastPage"
                :perPage="perPage"
                :tableData="tableData">
            </data-table-pagination>
            </div>
        </div>

    <template>
      <v-layout row justify-center>
        <v-dialog v-model="dialogFilters" persistent max-width="500px">
          <template v-slot:activator="{ on }">
            <v-toolbar-title v-on="on">
              <v-btn class="botonModal" id="btnBusquedaAvanzada"></v-btn>
            </v-toolbar-title>
          </template>

          <v-card>
            <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
              <div class="headerModal-filter">
                <h2 class="titleModalBuscar">{{ modalTitle }}</h2>
              </div>
            </v-card-title>

            <v-card-text class="card_texto pb-3" @keyup.enter="filter()">
              <v-container grid-list-md id="contenedor">
                <v-row>
                  <slot name="filters" v-if="filtersSlot"></slot>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions
              class="d-flex justify-end card_accion"
            >
              <v-spacer></v-spacer>
              <button @click="closeModal()" class="btnCancelar">
                Cerrar
              </button>
              <button @click="filter()" class="btnBuscar">
                Buscar
              </button>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
    </div>

</template>

<script>
import Vue from 'vue';
import UrlFilters from './mixins/UrlFilters';
import VueTable from './Table.vue';
import DataTableCell from './DatatableCell.vue';
import DataTableFilters from './DatatableFilters.vue';
import DataTablePagination from './DatatablePagination.vue';
import PerfectScrollbar from 'perfect-scrollbar';

export default {
    mixins: [UrlFilters],
    watch: {
        'tableData.items_to_show': function(newData, oldData) {
            if(newData != oldData){
                /**euicab cambio para remover el v-card en las tablas se le quita un padre
                 * this.$parent.$parent.paginationData.numberPage = 1; antes
                 */
                this.$parent.paginationData.numberPage = 1;
                this.$emit('pagination', {type: 'itemsToShow', value: newData});
            }
        }
    },
    components: {
        'vue-table'             : VueTable,
        'data-table-cell'       : DataTableCell,
        'data-table-filters'    : DataTableFilters,
        'data-table-pagination' : DataTablePagination,
    },
    data() {
        return {
            sortKey: '',
            Order  : 'DESC',
            sortOrders: {},
            tableData: {
                paginate        : true,
                items_to_show   : this.perPage[0],
            },
            dialogFilters: false,
        }
    },
    props: {
        emptyTable:{
            type :String,
            default: "emptyTable"
        },
        showAdvancedFilters: {
            type: Boolean,
            default: true,
        },
        filters: {
            type: Object,
            default: () => ({}),
        },
        tableName: {
            type :String,
            default: "Tabla"
        },
        modalTitle: {
            type: String,
            default: 'Búsqueda avanzada'
        },
        columns: {
            type: Array,
            default: () => ([]),
            required: true,
        },
        items: {
            type: Array,
            default: () => ([""]),
            required: true,
        },
        paginationInfo: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        perPage: {
            type: Array,
            default: () => ([
                '10',
                '25',
                '50'
            ]),
        },
        classes: {
            type: Object,
            default: () => ({
                'table-container': {
                    'table-responsive': true,
                },
                'table': {
                    'table': true,
                    'table-striped': true,
                    'table-dark': true,
                },
                't-head': {},
                't-body': {},
                'td': {},
                'th': {},
            }),
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        sortBy(key, columnName = null) {
            this.sortKey            = key;
            this.sortOrders[key]    = this.sortOrders[key] * -1;
            this.tableData.column   = columnName ? columnName : key;
            //this.tableData.dir     = this.sortOrders[key] === 1 ? 'DESC' : 'ASC';
            this.Order     = this.sortOrders[key] === 1 ? 'ASC' : 'DESC';
        },
        openModal(){
            this.$emit('loadModalData');
            setTimeout(function(){ document.getElementById("btnBusquedaAvanzada").click(); }, 100);
        },
        closeModal(){
            this.dialogFilters = false;
        },
        filter() {
        this.$emit("setFilters");
        //this.$emit("busquemos");
        this.closeModal(); 
        
        },
        getPageAction(data) {
            this.$emit('pagination', data);
        },
        tableScroll(){
            const elementScrollX = document.querySelector('#tableScrollX');
            if(elementScrollX) {
                const ps = new PerfectScrollbar(elementScrollX, {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20
                });
            }
        },
    },
    computed: {
        paginationSlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.pagination;
            }
            return null;
        },
        filtersSlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.filters;
            }
            return null;
        },
        bodySlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.body;
            }
            return null;
        },
        actionButtonsLeftSlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.actionButtonsLeft;
            }
            return null;
        },
        actionButtonsSlot() {
            if (this.$scopedSlots) {
                return this.$scopedSlots.actionButtons;
            }
            return null;
        },
    },
    updated(){
        this.tableScroll();
    },
    mounted() {
        this.tableScroll();
        this.columns.forEach((column) => {
            this.sortOrders[column.name] = -1;
        })
    },
}
</script>

<style scoped>

    .emptyTable{
        padding-top: 50px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #c5c5c5;
    }
    .emptyTableWhite{
        text-align: center;
        font-size: 18px;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #ffffff;
        color: #c5c5c5;
    }
    .display-row {
        /* display: inline-block; */
        text-align: center;
    }
    .display-row.pagination-container {
        width: 100%;
        text-align: right;
        background-color: #fdfcfc;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        padding: 0px 43px;
        padding-top: 20px;

        box-shadow: 4px 4px 8px rgba(196, 196, 196, 0.2);
    }
    .display-row.pagination-container > div {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: #c4c4c4;
    }
    /**Fin contenedor paginacion */

    .pull-right {
        /* text-align: right !important; */
        float: right;
    }

    /**Cambios de estilos tabla kardex */
    .margen {
        margin-bottom: 20px;
        margin-right: 10px;
    }
    /**fin cambios de estilos tabla kardex */
    .icon-container {
        width: 42px;
        height: 42px;
        background: #ffffff;
        border-radius: 100%;
        text-align: center;
        padding: 8px;
        transition: 0.3s;
        }

    .icon-container:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
    

#btnBusquedaAvanzada {
  display: none !important;
}

.titleModalBuscar {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #1E2245;
  padding-bottom: 25px;
}

.btnBuscar{
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  background-color: #1E2245 !important;
  border-radius: 30px !important;
  color: white !important;
  width: 110px !important;
  height: 40px !important;
  outline: none;
  text-transform: none !important;
  margin-left: 3%; 
  transition: all .2s;
}

.btnCancelar {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  background-color: #EEEEEE !important;
  color:#828282 !important;
  /*margin-right: 3%;euicab eliminamos el margen
  margin-top: 2%;*/
  outline: none;
  border-radius: 30px !important;
  width: 110px !important;
  height: 40px !important;
  transition: all .2s;
}
/* euicab se aplica un hover al boton cancelar */
.btnCancelar:hover {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
  background-color: #c7cacc !important;
  color: #FFF !important;
  /*margin-right: 1%;
  margin-top: 2%;*/
  outline: none;
  border-radius: 30px !important;
  width: 110px !important;
  height: 40px !important;
} 
 
@media only screen and (max-width: 743px) {
 .display-row.pagination-container {
        width: 100%;
        text-align: center;
        padding: 0px 0px;
    }
   
}
</style>